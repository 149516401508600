.pricing {
	text-align: center;
	border: 1px solid #f0f0f0;
	color: #777;
	font-size: 14px;
	padding-left: 0;
	margin-bottom: 30px;
  font-family: 'Roboto';
}

.pricing img {
	display: block;
	margin: auto;
	width: 32px;
}
.description{
  height: 300px;
}
.pricing li:first-child,
.pricing li:last-child {
	padding: 20px 13px;
}
.pricing li {
	list-style: none;
	padding: 13px;
}
.pricing li + li {
	border-top: 1px solid #f0f0f0;
}
.pricing big {
	font-size: 32px;
}
.pricing h3 {
	margin-bottom: 0;
  font-size: 36px;
}
.pricing span {
	font-size: 12px;
	color: #999;
	font-weight: normal;
}
.pricing li:nth-last-child(2) {
	padding: 30px 13px;
}
.pricing button {
	width: auto;
	margin: auto;
	font-size: 15px;
	font-weight: bold;
	border-radius: 50px;
	color: #fff;
	padding: 9px 24px;
	background: #aaa;
	opacity: 1;
	transition: opacity .2s ease;
  border: none;
  outline: none;
}
.pricing button:hover {
	opacity: .9;
}
.pricing button:active {
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
}