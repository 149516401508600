/* @media all and (min-width: 480px) { */
/* .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  } */
.paperLogin {
  padding: 1.5em;
}
.login-button {
  margin-top: 2em;
}
.Login .errorContainer {
  margin: 0 auto;
  max-width: 320px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #df0101;
  color: #df0101;
}

h1 {
  font-weight: 700;
}
/* } */
