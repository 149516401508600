.suggestionArea .space {
  height: 10px;
}

.pagecount-container {
  background: #74c3e6;
  background: var(--light-primary);
}
#search-result-count-paper {
  margin-top: 48px;
}

#search-result-count-paper-container {
  padding-bottom: 24px;
}
