.confirmation-dialog-title {
  text-align: center;
  font-size: 28px;
}

.confirmation-dialog-title-icon {
  display: block;
  text-align: center;
  font-size: 100px;
  color: #00a69f;
}

.confirmation-dialog {
  padding-bottom: 8px;
  padding-right: 20px;
}
