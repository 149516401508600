.iva {
  margin-left: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transform: scaleX(-1);
}
.helpBox {
  position: relative;
  margin-left: 10px;
  padding: 10px;
}
.helpBox::after {
  content: "";
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) 2px 1px 2px;
  transform: rotate(135deg);
  left: -10px;
  top: 50px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #fff #fff transparent;
}
