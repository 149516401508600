.booking-option-icon {
	margin-right: 20px
}

.booking-option-enabled {
	color: #39a9dc;
}

.booking-option-disabled {
	color: #bababa;
}